import React from "react";
import styles from './WithdrawSum.module.scss';

const WithdrawSum = ({balance, text, token, networkInfo}) => {
    function isInteger(num) {
        return (num ^ 0) === num;
    }

    return (
        <div className={styles.withdrawSum}>
            <div>
                <p>{text.fee}</p>
                {networkInfo && (
                    <p>{isInteger(Number(networkInfo.fee)) ? Number(networkInfo.fee).toFixed(2) : networkInfo.fee} {token}</p>
                )}
            </div>
            <div>
                <p>{text.minimum}</p>
                {networkInfo && (
                    <p>{isInteger(Number(networkInfo.min_amount)) ? Number(networkInfo.min_amount).toFixed(2) : networkInfo.min_amount} {token}</p>
                )}
            </div>
            <div>
                <p>{text.maximum}</p>
                {networkInfo && (
                    <>
                        <p>{isInteger(Number(balance) - Number(networkInfo.fee)) ? (Number(balance) - Number(networkInfo.fee)).toFixed(2) : (Number(balance) - Number(networkInfo.fee))} {token}</p>
                    </>
                )}
            </div>
        </div>
    )
}

export default WithdrawSum;