import React from "react";
import styles from './TransactionItem.module.scss';
import sendIcon from '../../../../../assets/icons/send.svg';
import recieveIcon from '../../../../../assets/icons/recieve.svg';

const TransactionItem = ({item, setSelectedTransaction}) => {
    const redoneHash = `${item?.hash?.substring(0, 4)}...${item?.hash?.substring(item.hash.length-5, item.hash.length)}`;

    return (
        <li className={styles.transactionItem} onClick={() => setSelectedTransaction(item)}>
            <div className={styles.transactionItem__header}>
                <div className={styles.transactionItem__operation}>
                    <img src={item.isRecieve ? recieveIcon : sendIcon} alt=""/>
                    <span>{item?.hash ? redoneHash : ''}</span>
                </div>
                <div className={styles.transactionItem__value}>
                    <span>{item.isRecieve ? '+' : '-'}{item.amount}</span>
                    <span>{item.token}</span>
                </div>
            </div>
            <div className={styles.transactionItem__date}>
                <span>{item.date}</span>
                <span>{item.time}</span>
            </div>
        </li>
    )
}

export default TransactionItem;