import React from "react";
import styles from './TransactionsList.module.scss';
import TransactionItem from "./TransactionItem/TransactionItem";

const TransactionsList = ({transactionsHistoryList, setSelectedTransaction, text, onShowMore, page, totalPage}) => {
    console.log(page)
    console.log(totalPage)

    return (
        <ul className={styles.transactionsList}>
            {transactionsHistoryList && (
                transactionsHistoryList.map((item, index) => (
                    <TransactionItem key={index} item={item} setSelectedTransaction={setSelectedTransaction} />
                ))
            )}
            {page !== totalPage && (
                <div className={styles.showMore}>
                    <button onClick={() => onShowMore()}>
                        <span>{text.showMore}</span>
                    </button>
                </div>
            )}
        </ul>
    )
}

export default TransactionsList;