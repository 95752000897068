import React, {useState} from "react";
import styles from './History.module.scss';
import {PagesHeader} from "../Common/PagesHeader/PagesHeader";
import HistoryChoose from "./HistoryChoose/HistoryChoose";
import {Route, Routes, useLocation, useNavigate} from "react-router";
import HistoryTransactions from "./HistoryTransactions/HistoryTransactions";
import HistoryOrders from "./HistoryOrders/HistoryOrders";
import {useSelector} from "react-redux";
import {getText} from "../../store/selectors";

const History = () => {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const [historyChoose, setHistoryChoose] = useState('orders');
    const {history} = useSelector(getText);

    if (location === '/history' || location === '/history/') {
        navigate('/history/orders/');
    }

    return (
        <div className={`${styles.history} bgMain`}>
            <PagesHeader headerText={history.title} />
            <HistoryChoose historyChoose={historyChoose} setHistoryChoose={setHistoryChoose} />
            <Routes>
                <Route path={'orders/*'} element={<HistoryOrders history={history} />} />
                <Route path={'transactions/*'} element={<HistoryTransactions history={history} />} />
            </Routes>

        </div>
    )
}

export default History;