const baseURL = 'https://cbexbot.gr-pro.org/api/v2/';

export const refreshAccessToken = async (refreshToken) => {
    try {
        const response = await fetch(`${baseURL}jwt-token/refresh/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh_token: refreshToken,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getUserInfo = async (value, access) => {
    try {
        const response = await fetch(`${baseURL}home/start_web_app/${value}/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getWalletsInfo = async (value, access) => {
    try {
        const response = await fetch(`${baseURL}wallets/${value}/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getTokensAML = async (access) => {
    try {
        const response = await fetch(`${baseURL}aml_commission/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getTickersList = async (value, access) => {
    try {
        const response = await fetch(`${baseURL}tickers/list/?telegram_id=${value}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getTickersToken = async (value, token, access) => {
    try {
        const response = await fetch(`${baseURL}tickers/list/?telegram_id=${value}&token=${token}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getTickersFavorite = async (value, isFavorite, access) => {
    try {
        const response = await fetch(`${baseURL}tickers/list/?telegram_id=${value}&is_favorite=${isFavorite}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getGeneralAddress = async (value, access) => {
    try {
        const response = await fetch(`${baseURL}withdrawal/get_general_address/?network_type=${value}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getHistoryOrdersCVV = async (telegramId, startDate, endDate, type, symbol, access) => {
    try {
        const response = await fetch(`${baseURL}orders_history_cvv/${telegramId}/?start_date=${startDate}&end_date=${endDate}&type=${type}&symbol=${symbol}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getHistoryTransactionsCVV = async (telegramId, startDate, endDate, type, symbol, access) => {
    try {
        const response = await fetch(`${baseURL}transactions_history_cvv/${telegramId}/?start_date=${startDate}&end_date=${endDate}&type=${type}&token=${symbol}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getOrdersHistory = async (value, startDate, endDate, type, tokenPair, access, page) => {
    try {
        const response = await fetch(`${baseURL}orders_history/${value}/?start_date=${startDate}&end_date=${endDate}&type=${type}&symbol=${tokenPair}&page=${page}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}
//
// export const getExchangePrice = async (value, access) => {
//     try {
//         const response = await fetch(`${baseURL}exchange_price/?symbol=${value}`, {
//             method: "GET",
//             headers: {
//                 Authorization: `Bearer ${access}`,
//             },
//         });
//         return await response.json();
//     } catch (error) {
//         console.log(error);
//     }
// }

export const getHistoryTransactionsBase = async (value, startDate, endDate, type, token, access, page) => {
    try {
        const response = await fetch(`${baseURL}transactions_history/${value}/?start_date=${startDate}&end_date=${endDate}&type=${type}&token=${token}&page=${page}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getOpenOrders = async (telegramId, access) => {
    try {
        const response = await fetch(`${baseURL}open_orders/?telegram_id=${telegramId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getQRCode = async (network, address, access) => {
    try {
        const response = await fetch(`${baseURL}qr_code/generate/${network}/${address}/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getAuthCode = async (id, access) => {
    try {
        const response = await fetch(`${baseURL}google_auth/generate_code/${id}/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getAllTokens = async (access) => {
    try {
        const response = await fetch(`${baseURL}tokens/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getAllNetworkList = async (access) => {
    try {
        const response = await fetch(`${baseURL}networks/list/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getAllTickers = async (id, access) => {
    try {
        const response = await fetch(`${baseURL}tickers/all/?telegram_id=${id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getAvailableTokens = async (token, access) => {
    try {
        const response = await fetch(`${baseURL}available_tokens/?token=${token}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getTokenUsdPrice = async (token, access) => {
    try {
        const response = await fetch(`${baseURL}exchange_price/?symbol=${token}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setTwoFactorCode = async (id, code, access) => {
    try {
        const response = await fetch(`${baseURL}google_auth/verify_code/${id}/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const sendSwap = async (telegramId, tokenFrom, tokenTo, value, access) => {
    try {
        const response = await fetch(`${baseURL}swap/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                telegram_id: telegramId,
                token_from: tokenFrom,
                token_to: tokenTo,
                quantity: value,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const removeOpenOrders = async (orderId, telegramId, access) => {
    try {
        const response = await fetch(`${baseURL}cancel_order/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                order_id: orderId,
                telegram_id: telegramId
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setTheme = async (id, theme, access) => {
    try {
        const response = await fetch(`${baseURL}home/set_theme/${id}/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                theme: theme,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const updateWallet = async (network, address, name, access) => {
    try {
        const response = await fetch(`${baseURL}wallets/${network}/${address}/`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setChangeFavorite = async (telegramId, tickerId, access) => {
    try {
        const response = await fetch(`${baseURL}tickers/changetofavorite/`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                telegram_id: telegramId,
                ticker_id: tickerId,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const generateWalletAddress = async (telegramId, network, access) => {
    try {
        const response = await fetch(`${baseURL}generate_address/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                telegram_id: telegramId,
                network: network,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setAuthStatus = async (id, status, access) => {
    try {
        const response = await fetch(`${baseURL}google_auth/set_status/${id}/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: status,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const SendExchange = async (operation, couple, value, telegramId, access) => {
    try {
        const response = await fetch(`${baseURL}exchange/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                operation: operation,
                symbol: couple,
                quantity: value,
                telegram_id: telegramId,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const sendWithdrawal = async (network, telegramId, toAddress, token, value, addressFrom, commission, access) => {
    try {
        const response = await fetch(`${baseURL}withdrawal/create/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                network_type: network,
                telegram_id: telegramId,
                address_to: toAddress,
                token: token,
                amount: value,
                address_from: addressFrom,
                commission: commission,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const sendLimitOrder = async (operation, couple, value, telegramId, clientPrice, access) => {
    try {
        const response = await fetch(`${baseURL}limit_orders/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                operation: operation,
                symbol: couple,
                quantity: value,
                telegram_id: telegramId,
                client_price: clientPrice,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const createWallet = async (network, telegramId, name, address, access) => {
    try {
        const response = await fetch(`${baseURL}wallets/${network}/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                telegram_id: telegramId,
                name: name,
                address: address,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const sendAML = async (network, address, access) => {
    try {
        const response = await fetch(`${baseURL}aml_check/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${access}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                network: network,
                address: address,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const deleteWallet = async (address, network, access) => {
    try {
        const response = await fetch(`${baseURL}wallets/${network}/${address}/`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

