import React from "react";
import styles from './HistoryOrdersList.module.scss';
import HistoryOrderItem from "./HistoryOrderItem/HistoryOrderItem";

const HistoryOrdersList = ({ordersHistoryList, setSelectedOrder, text, onShowMore, page, totalPage}) => {

    return (
        <div className={styles.historyOrdersList}>
            <ul>
                {ordersHistoryList && ordersHistoryList.length > 0 && (
                    ordersHistoryList.map((item, index) => (
                        <HistoryOrderItem item={item} key={index} setSelectedOrder={setSelectedOrder} />
                    ))
                )}
                {page !== totalPage && (
                    <div className={styles.showMore}>
                        <button onClick={() => onShowMore()}>
                            <span>{text.showMore}</span>
                        </button>
                    </div>
                )}
            </ul>
        </div>
    )
}

export default HistoryOrdersList;