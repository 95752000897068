import React, {useEffect, useState} from "react";
import styles from './HistoryOrders.module.scss';
import upIcon from "../../../assets/icons/upIcon.svg";
import upIconBlue from "../../../assets/icons/upIconBlue.svg";
import HistoryOrdersList from "./HistoryOrdersList/HistoryOrdersList";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getTheme, getUserId} from "../../../store/selectors";
import HistoryOrdersPopup from "./HistoryOrdersPopup/HistoryOrdersPopup";
import {getHistoryOrdersCVV, getOrdersHistory, refreshAccessToken} from "../../../api/api";
import OrderItemHistory from "./OrderItemHistory/OrderItemHistory";
import {Preloader} from "../../Common/Preloader/Preloader";
import {useNavigate} from "react-router";
import {setAcToken} from "../../../store/account-slice";
import {useAppDispatch} from "../../../hooks/redux";


const HistoryOrders = ({history}) => {
    const theme = useSelector(getTheme);
    const navigate = useNavigate();
    const [showOrders, setShowOrders] = useState(false);
    const [ordersHistoryList, setOrdersHistoryList] = useState(null)
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [finishDate, setFinishDate] = useState('');
    const [ordersChoose, setOrdersChoose] = useState('All');
    const [selectedTicker, setSelectedTicker] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);


    const onGetCVV = () => {
        const formatStartDate = `${startDate.$d.getFullYear()}-${startDate.$d.getMonth() + 1}-${startDate.$d.getDate()}`;
        const formatEndDate = `${finishDate.$d.getFullYear()}-${finishDate.$d.getMonth() + 1}-${finishDate.$d.getDate()}`;

        getHistoryOrdersCVV(telegramId, formatStartDate, formatEndDate, ordersChoose, selectedTicker, accessToken).then(result => {
            if (result && result.detail && result.detail === 'access_token expired') {
                refreshAccessToken(refreshToken).then(r => {
                    if (r && r.access_token) {
                        dispatch(setAcToken(r.access_token));
                        getHistoryOrdersCVV(telegramId, formatStartDate, formatEndDate, ordersChoose, selectedTicker, r.access_token).then(result => {
                            if (result && result.file_path) {
                                navigate(`${result.file_path}`);
                            }
                        })
                    }
                });
            }
            if (result && result.file_path) {
                navigate(`${result.file_path}`);
            }
        })
    }

    useEffect(() => {
        if (telegramId) {
            getOrdersWithFilters();
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setIsLoading(false);
            }, 2500)
        }
    }, [telegramId])

    useEffect(() => {
        getOrdersWithFilters();
        setPage(1);
    }, [startDate, finishDate, ordersChoose, selectedTicker])

    const getOrdersWithFilters = (sendPage = 1) => {
        if (startDate.$d && finishDate.$d) {
            const formatStartDate = `${startDate.$d.getFullYear()}-${startDate.$d.getMonth() + 1}-${startDate.$d.getDate()}`;
            const formatEndDate = `${finishDate.$d.getFullYear()}-${finishDate.$d.getMonth() + 1}-${finishDate.$d.getDate()}`;
            const formatType = ordersChoose.toLowerCase();

            getOrdersHistory(telegramId, formatStartDate, formatEndDate, formatType, selectedTicker, accessToken, sendPage).then((result) => {
                if (result && result.detail && result.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getOrdersHistory(telegramId, formatStartDate, formatEndDate, formatType, selectedTicker, r.access_token, sendPage).then((result) => {
                                if (result.orders) {
                                    sendPage === 1 ? setOrdersHistoryList(result.orders) : setOrdersHistoryList([...ordersHistoryList, ...result.orders]);
                                    if (result.total_pages) {
                                        setTotalPage(result.total_pages)
                                    }
                                    setIsLoading(false);
                                } else {
                                    setIsLoading(false);
                                }
                            })
                        }
                    });
                }
                console.log(result)
                if (result.orders) {
                    sendPage === 1 ? setOrdersHistoryList(result.orders) : setOrdersHistoryList([...ordersHistoryList, ...result.orders]);
                    if (result.total_pages) {
                        setTotalPage(result.total_pages)
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
        }
    }

    const onShowMore = () => {
        getOrdersWithFilters(page + 1);
        setPage(page + 1);
        setIsLoading(true);
    }

    return (
        <div className={styles.orderHistory}>
            <button onClick={() => setShowOrders(true)} className={`${styles.filter} bgBlock`}>
                <span>{history.filter}</span>
                <img src={theme === 'dark' ? upIcon : upIconBlue} alt=""/>
            </button>
            <HistoryOrdersList ordersHistoryList={ordersHistoryList} setSelectedOrder={setSelectedOrder} text={history}
                               onShowMore={onShowMore} page={page} totalPage={totalPage}/>
            <HistoryOrdersPopup showOrders={showOrders} setShowOrders={setShowOrders} startDate={startDate}
                                setStartDate={setStartDate} finishDate={finishDate} setFinishDate={setFinishDate}
                                ordersChoose={ordersChoose} setOrdersChoose={setOrdersChoose}
                                setSelectedTicker={setSelectedTicker} onGetCVV={onGetCVV}/>
            <OrderItemHistory selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder}/>
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default HistoryOrders;